<template>
  <v-dialog v-model="show" max-width="550" persistent>
    <v-card :style="'height: 550px ; max-height: 550px '">
      <v-card-title class="text-h5">
        <v-row>
          <v-col cols="11" class="title-asbestos" v-if="item">
            {{ item.desc.length < 46 ? item.desc : item.desc.substring(0, 45) + '...' }}
          </v-col>
          <v-col cols="1">
            <v-btn id="btnCloseX" @click="close(false)" :elevation="0" icon :ripple="false">
              <v-icon class="material-icons-round">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text id="asbestos-containe">
        <p style="color: #52526b !important">
          Total de {{ funcTitle.toLowerCase() }} expostos: {{ collaborators.length }}
        </p>
        <v-data-table :items="collaborators" hide-default-header :hide-default-footer="true" class="custom-table">
          <template v-slot:item="{ item }">
            <tr>
              <td style="font-size: 16px !important">{{ item.matriculation }} - {{ item.name }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="IdBtnClose" color="success" @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'ModalAgentesNocivos',
  props: ['item'],

  updated() {
    this.customize();
  },
  data() {
    return {
      show: false,
      funcTitle: process.env.VUE_APP_TAGFUNC,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      collaborators: 'wss/getCollaborators',
    }),
  },
  methods: {
    ...mapActions('wss', ['specificFilter', 'cleanFilterTags']),
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },
    open() {
      this.show = true;
    },
    close() {
      this.cleanFilterTags();
      this.specificFilter();
      this.show = false;
    },
    customize() {
      let tableLines = document.querySelectorAll('tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  overflow-y: hidden !important;
}
#asbestos-containe {
  background-color: #e7e7fa;
  border-radius: 3px;
  height: 406px !important;
  max-height: 406px !important;
  flex-grow: 1;
  overflow: auto;
  padding-top: 16px;
}
.title-asbestos {
  width: 453px;
  height: 30px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #182026;
}
p {
  width: 380px;
  height: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6b5752;
}
.custom-table .v-data-table__wrapper {
  border-radius: 3px !important;
}
.custom-table {
  border-radius: 3px !important;
}
tr {
  height: 56px;
  width: 501px;
  left: 0px;
  top: 0px;
  padding: 16px;
}
td {
  width: 380px;
  height: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #52526b;
  border-bottom: none !important;
}
#btnCloseX:hover {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  width: 32px !important;
  height: 32px !important;
}
#btnCloseX:active {
  backgound-color: var(--v-primary-base) !important;
  color: #ffffff !important;
  box-shadow: none !important;
  width: 32px !important;
  height: 32px !important;
}
#btnCloseX {
  border-radius: 100% !important;
  box-shadow: none !important;
  background: white !important;
  padding: 16px !important;
  width: 32px !important;
  height: 32px !important;
}
.v-card-actions {
  padding: 16px !important;
}
#IdBtnClose {
  width: 82px;
  height: 48px;
  padding: 12px 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}
</style>
