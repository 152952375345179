var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { style: "height: 550px ; max-height: 550px " },
        [
          _c(
            "v-card-title",
            { staticClass: "text-h5" },
            [
              _c(
                "v-row",
                [
                  _vm.item
                    ? _c(
                        "v-col",
                        {
                          staticClass: "title-asbestos",
                          attrs: { cols: "11" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.item.desc.length < 46
                                  ? _vm.item.desc
                                  : _vm.item.desc.substring(0, 45) + "..."
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "btnCloseX",
                            elevation: 0,
                            icon: "",
                            ripple: false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.close(false)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "material-icons-round" },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { attrs: { id: "asbestos-containe" } },
            [
              _c("p", { staticStyle: { color: "#52526b !important" } }, [
                _vm._v(
                  " Total de " +
                    _vm._s(_vm.funcTitle.toLowerCase()) +
                    " expostos: " +
                    _vm._s(_vm.collaborators.length) +
                    " "
                ),
              ]),
              _c("v-data-table", {
                staticClass: "custom-table",
                attrs: {
                  items: _vm.collaborators,
                  "hide-default-header": "",
                  "hide-default-footer": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c("tr", [
                          _c(
                            "td",
                            { staticStyle: { "font-size": "16px !important" } },
                            [
                              _vm._v(
                                _vm._s(item.matriculation) +
                                  " - " +
                                  _vm._s(item.name)
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { id: "IdBtnClose", color: "success" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Fechar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }